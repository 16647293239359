import React from 'react';
import './App.css';
import facebookLogo from './assets/facebook-logo.png';
import instagramLogo from './assets/facebook-logo.png';
import heroBanner from './assets/hero-banner.png';
import siteIcon from './assets/fig-1.png';
import whatsappLogo from './assets/whatsapp-logo.png';



function App() {
  return (
    <div className="App">
      <header>
        <nav className="main-nav">
          <ul>
            <li className="nav-left">
              <a href="#hero">
                <img src={siteIcon} alt="Ícone do site" className="site-icon" />
              </a>
            </li>

            <li className="nav-center">
              <nav>
                <ul>
                  <li><a href="#hero">Início</a></li>
                  <li><a href="#about">Quem sou</a></li>
                  <li><a href="#info">Propostas</a></li>
                  <li><a href="#contact">Contato</a></li>
                </ul>
              </nav>
            </li>

            <li className="nav-right">
              <nav>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                      <img src={facebookLogo} alt="Facebook" className="site-icon" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                      <img src={instagramLogo} alt="Instagram" className="site-icon" />
                    </a>
                  </li>
                </ul>
              </nav>
            </li>
          </ul>
        </nav>
      </header>

      <section id="hero">
        <div className="hero-image">
          <img src={heroBanner} alt="Foto Pessoal" />
        </div>
      </section>

      <section id="about">
        <h2>Quem sou</h2>
        <p>
          Meu nome é Joel Neves e sou residente da Barra do Saí. Tenho uma trajetória profissional de 20 anos como
          bancário, dos quais 16 anos foram dedicados ao Banestado (Banco do Estado do Paraná), onde atuei como chefe
          de crédito imobiliário. Atualmente, estou envolvido em projetos e execuções relacionados a mármores e
          granitos. Candidato a vereador, meu compromisso é defender e propor ações sociais voltadas para a proteção
          de nossos idosos e crianças, que representam o futuro de nosso município. Também pretendo apoiar as
          pequenas, médias e grandes empresas, essenciais para o desenvolvimento local. Conto com o apoio de todos
          para construirmos uma Itapoá melhor.
        </p>
      </section>

      <section id="info">
        <h2>Propostas</h2>
        <p>
          Aqui estão mais detalhes sobre minha trajetória, habilidades e experiências. Eu me especializo em
          arquiteturas escaláveis e integrações complexas.
        </p>
      </section>

      <section id="contact">
        <h2>Contato</h2>
        <p>
          Entre em contato: <a href="mailto:email@exemplo.com">email@exemplo.com</a>
        </p>
      </section>

      <a href="https://wa.me/5511999999999" className="whatsapp-button" target="_blank" rel="noreferrer">
        <img src={whatsappLogo} alt="WhatsApp" />
      </a>

      <footer>
        <p>&copy; 2024 Página Pessoal. Todos os direitos reservados.</p>
      </footer>
    </div>
  );
}

export default App;
